import React from 'react';
import {Col, Row} from "antd";
import {get, find, every} from 'lodash';

import QuickEditUpdateDelivery from "./QuickEditUpdateDelivery";
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {formatWeight, getVar} from "../../../../system/support/helpers";
import {currentFormatter} from './../../../Common/services/format';
import ConfirmDeliveryNoteSuccess from './ConfirmDeliveryNoteSuccess';
import authService from '../../../Auth/authService';
import PERMISSIONS from '../../../Auth/permissions';
import { DELIVERY_NOTE_STATUS } from '../../constants';
import { DELIVERY_REQUEST_STATUS } from '../../../DeliveryRequest/ListDeliveryRequest/constants';

class InfoCustomerDeliveryNote extends React.Component {

    render() {
        let {customer, t, delivery_note, permissions, agencySetting, globalSetting} = this.props;
        const status = getVar(delivery_note, "status", "");
        const deliveryRequests = getVar(delivery_note, "delivery_requests", []);
        const id_agency = getVar(delivery_note, "customer_id_agency")
        const hasPermission = getVar(find(agencySetting, {code: "CONFIG:LASTMILE_DELIVERY_REQUEST", id_agency}), "value", get(find(globalSetting, ["code", "CONFIG:LASTMILE_DELIVERY_REQUEST"]), "value", ));
        const allDeliveryRequestAllow = every(deliveryRequests, ['status', DELIVERY_REQUEST_STATUS.STATUS_DELIVERING]) || every(deliveryRequests, ['status', DELIVERY_REQUEST_STATUS.STATUS_COMPLETED])
        const canConfirm = allDeliveryRequestAllow && hasPermission && authService.can(PERMISSIONS.DELIVERY_NOTE_CONFIRM) && status === DELIVERY_NOTE_STATUS.EXPORTED && deliveryRequests.length > 0
        
        return (
            <div className="block--primary a-block-info abg-white detail-delivery-note ">
                <div className="title gflex galign-center gjustify-space-between">
                    <span>{t("delivery_note:title.detail_delivery_note")}</span>
                    {
                        !!canConfirm && <ConfirmDeliveryNoteSuccess deliveryNote={delivery_note} fetchDeliveryNote={this.props.fetchDeliveryNote} />
                    }
                </div>
                <div className="body">
                    <Row className="mx-0" gutter={{lg: 24, xl: 32}} type="flex">
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t("delivery_note:label.creator")}</label>
                            <span className="info--value ">{delivery_note.creator}</span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t("delivery_note:label.created_at")}</label>
                            <span className="info--value ">{delivery_note.createdAtFormat}</span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t('status')}</label>
                            <span className="info--value ">{t(`delivery_note:status.${delivery_note.status}`)}</span>
                        </Col>
                        <Col lg={{span: 12}} xl={{span: 8}} xxl={{span: 8}}
                             className="info--group">
                            <label className="info--label">{t("delivery_note:label.exporter")}</label>
                            <span className="info--value ">{delivery_note.exporter}</span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t("delivery_note:label.exported_at")}</label>
                            <span className="info--value ">{delivery_note.exportedAtFormat}</span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t("delivery_note:label.warehouse")}</label>
                            <span className="info--value ">{delivery_note.warehouse}</span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t('lwh_dimension')}</label>
                            <span className="info--value">
                                {delivery_note.length || delivery_note.width || delivery_note.height ? (
                                    <>
                                        {delivery_note.length || 0}x{delivery_note.width || 0}x{delivery_note.height || 0}
                                    </>   
                                ) : '--'}
                            </span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t("delivery_note:label.package_number")}</label>
                            <span className="info--value ">{delivery_note.total_number}</span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t("delivery_note:label.package_weight")}</label>
                            <span className="info--value ">{formatWeight(delivery_note.total_weight)}</span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t('shipping_fee')} (VNĐ)</label>
                            <span className="info--value">
                                {currentFormatter.toLocaleStringCurrency(delivery_note.domestic_shipping_fee)}
                            </span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t('negative_balance')} (VNĐ)</label>
                            <span className="info--value">
                                {currentFormatter.toLocaleStringCurrency(delivery_note.negative_balance)}
                            </span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">COD (VNĐ)</label>
                            <span className="info--value">
                                {currentFormatter.toLocaleStringCurrency(delivery_note.cod)}
                            </span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t('label.lastmile_operation_fee')} (VNĐ)</label>
                            <span className="info--value">
                                {currentFormatter.toLocaleStringCurrency(delivery_note.lastmile_operation_fee)}
                            </span>
                        </Col>
                        <Col className="info--group" lg={{span: 8}}>
                            <QuickEditUpdateDelivery
                                {...this.props}
                                value={delivery_note.note}
                                permissions={permissions.can_update_direction}
                                id={delivery_note.id}
                            />
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t('shipping_partner')}</label>
                            <span className="info--value">
                                {delivery_note.shippingDomesticPartner}
                            </span>
                        </Col>
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t('current_balance_of_customer')} (VNĐ)</label>
                            <span className="info--value">
                                {currentFormatter.toLocaleStringCurrency(get(customer, 'customer_finance.amount_remaining'))}
                            </span>
                        </Col>
                    </Row>
                    <Row className="mx-0" gutter={{lg: 24, xl: 32}} type="flex">
                        <Col className="info--group" lg={{span: 12}} xl={{span: 8}}>
                            <label className="info--label">{t("delivery_note:label.customer_receiver")}</label>
                            <span className="info--value ">{delivery_note.customer_receiver || "--"}</span>
                        </Col>
                        <Col className="info--group" lg={{span: 8}}>
                            <label className="info--label">{t("delivery_note:label.customer_phone")}</label>
                            <span className="info--value ">{delivery_note.customer_phone || '--'}</span>
                        </Col>
                        <Col className="info--group" lg={{span: 8}}>
                            <label className="info--label">{t("delivery_note:label.customer_address")}</label>
                            <span className="info--value ">{delivery_note.customer_address || '--'}</span>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

InfoCustomerDeliveryNote.defaultProps = {
    delivery_note: {},
    onEdit: () => {}
};

InfoCustomerDeliveryNote.propTypes = {
    deliveryNote: PropTypes.object,
};

export default withTranslation()(InfoCustomerDeliveryNote);
