export const FETCH_LIST_WAREHOUSE = {
    REQUEST: 'FETCH_LIST_WAREHOUSE.REQUEST',
    SUCCESS: 'FETCH_LIST_WAREHOUSE.SUCCESS',
    FAILED: 'FETCH_LIST_WAREHOUSE.FAILED',
};
export const FETCH_DETAIL_WAREHOUSE = {
    REQUEST: 'FETCH_DETAIL_WAREHOUSE.REQUEST',
    SUCCESS: 'FETCH_DETAIL_WAREHOUSE.SUCCESS',
    FAILED: 'FETCH_DETAIL_WAREHOUSE.FAILED',
};
export const FETCH_LIST_USER_WAREHOUSE = {
    REQUEST: 'FETCH_LIST_USER_WAREHOUSE.REQUEST',
    SUCCESS: 'FETCH_LIST_USER_WAREHOUSE.SUCCESS',
    FAILED: 'FETCH_LIST_USER_WAREHOUSE.FAILED',
};
export const CREATE_WAREHOUSE = {
    REQUEST: 'CREATE_WAREHOUSE.REQUEST',
    SUCCESS: 'CREATE_WAREHOUSE.SUCCESS',
    FAILED: 'CREATE_WAREHOUSE.FAILED',
};
export const UPDATE_WAREHOUSE = {
    REQUEST: 'UPDATE_WAREHOUSE.REQUEST',
    SUCCESS: 'UPDATE_WAREHOUSE.SUCCESS',
    FAILED: 'UPDATE_WAREHOUSE.FAILED',
};
export const DELETE_WAREHOUSE = {
    REQUEST: 'DELETE_WAREHOUSE.REQUEST',
    SUCCESS: 'DELETE_WAREHOUSE.SUCCESS',
    FAILED: 'DELETE_WAREHOUSE.FAILED',
};
export const CREATE_STAFF_IN_WAREHOUSE = {
    REQUEST: 'CREATE_STAFF_IN_WAREHOUSE.REQUEST',
    SUCCESS: 'CREATE_STAFF_IN_WAREHOUSE.SUCCESS',
    FAILED: 'CREATE_STAFF_IN_WAREHOUSE.FAILED',
};
export const REMOVE_STAFF_IN_WAREHOUSE = {
    REQUEST: 'REMOVE_STAFF_IN_WAREHOUSE.REQUEST',
    SUCCESS: 'REMOVE_STAFF_IN_WAREHOUSE.SUCCESS',
    FAILED: 'REMOVE_STAFF_IN_WAREHOUSE.FAILED',
};

export const FETCH_LIST_WAREHOUSE_STAFF = {
    REQUEST: 'FETCH_LIST_WAREHOUSE_STAFF.REQUEST',
    SUCCESS: 'FETCH_LIST_WAREHOUSE_STAFF.SUCCESS',
    FAILED: 'FETCH_LIST_WAREHOUSE_STAFF.FAILED',
};
export const FETCH_DETAIL_WAREHOUSE_STAFF = {
    REQUEST: 'FETCH_DETAIL_WAREHOUSE_STAFF.REQUEST',
    SUCCESS: 'FETCH_DETAIL_WAREHOUSE_STAFF.SUCCESS',
    FAILED: 'FETCH_DETAIL_WAREHOUSE_STAFF.FAILED',
};
export const CREATE_WAREHOUSE_STAFF = {
    REQUEST: 'CREATE_WAREHOUSE_STAFF.REQUEST',
    SUCCESS: 'CREATE_WAREHOUSE_STAFF.SUCCESS',
    FAILED: 'CREATE_WAREHOUSE_STAFF.FAILED',
};
export const GET_WAREHOUSE_STAFF_ID = {
    REQUEST: 'GET_WAREHOUSE_STAFF_ID.REQUEST',
    SUCCESS: 'GET_WAREHOUSE_STAFF_ID.SUCCESS',
    FAILED: 'GET_WAREHOUSE_STAFF_ID.FAILED'
};
export const UPDATE_WAREHOUSE_STAFF = {
    REQUEST: 'UPDATE_WAREHOUSE_STAFF.REQUEST',
    SUCCESS: 'UPDATE_WAREHOUSE_STAFF.SUCCESS',
    FAILED: 'UPDATE_WAREHOUSE_STAFF.FAILED',
};
export const CLEAR_STATE = 'CLEAR_STATE';
export const RESET_ERROR = 'RESET_ERROR';
export const RESET_LIST_WAREHOUSE = 'RESET_LIST_WAREHOUSE';
export const RESET_LIST_WAREHOUSE_STAFF = 'RESET_LIST_WAREHOUSE_STAFF';
export const DEFAULT_STATE_FORM_INPUT_WAREHOUSE = {
    name: '',
    code: '',
    id_location: '',
    note: '',
    address: '',
    phone: '',
};
export const DEFAULT_STATE_FORM_INPUT_WAREHOUSE_STAFF = {
    id: 0,
    id_warehouses: [],
    id_user: 0,
    note: '',
};
export const DEFAULT_STATE_ADD_STAFF_IN_WAREHOUSE = {
    id_users: [],
    visible: false,
    code: "",
    users: []
};
export const LIST_WAREHOUSE_COLUMN = {
    CODE: 'code_warehouse',
    NAME: 'name_warehouse',
    PHONE: "phone",
    ADDRESS: 'address',
    // NOTE: 'note',
    ACTION: 'action',

};
export const LIST_WAREHOUSE_STAFF_COLUMN = {
    CODE: 'code_warehouse_staff',
    NAME: 'name_warehouse_staff',
    EMAIL: 'email',
    PHONE: 'phone',
    WAREHOUSE: 'warehouse_charge',
    ACTION: 'action',

};
export const LIST_STAFF_IN_WAREHOUSE_COLUMN = {
    USERNAME: 'username',
    NAME: 'name_warehouse_staff',
    EMAIL: 'email',
    PHONE: 'phone',
    CREATE_AT: 'create_at',
    CREATOR: 'creator',
    ACTION: 'action',
};
