/* eslint-disable */
import React, { Component } from 'react';
import { Table } from 'antd';
import clsx from 'clsx';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { LIST_WAREHOUSE_STAFF_COLUMN } from './../../constants';

class List extends Component {
	getColumns = () => {
		return lodash.values(LIST_WAREHOUSE_STAFF_COLUMN).map(column => {
			return {
				key: column,
				dataIndex: column,
				title: column !== LIST_WAREHOUSE_STAFF_COLUMN.ACTION ? this.props.t('config:column.' + column) : '',
				fixed: column === LIST_WAREHOUSE_STAFF_COLUMN.ACTION && 'right',
				render: (text, row) => {
					const active = row.active ? 'a-notification-dot a-notification-dot--table active' : 'a-notification-dot a-notification-dot--table unactive';
					const listClass = {
						code_warehouse_staff: active,
						action: 'a-text--right'
					};
					return {
						children: text,
						props: {
							className: clsx(listClass[column], `_row-${column}`, {
								'a-text--nowrap': column !== LIST_WAREHOUSE_STAFF_COLUMN.WAREHOUSE
							})
						}
					};
				}
			};
		});
	};

	render() {
		const { listWarehouseStaff, loading } = this.props;

		return (
			<div className="a-content__body bg-white a-content__body--page-list height-100">
				<Table
					className="a-table--antd a-table--antd-scroll-x _a-table-warehouse-staff"
					columns={this.getColumns()}
					dataSource={listWarehouseStaff}
					loading={loading}
					pagination={false}
					rowKey={(record) => {
						return record.key
					}}
					scroll={{x: 'fit-content'}}
				/>
			</div>
		);
	}
}

List.defaultProps = {
	listWarehouseStaff: [],
	loading: false
};

List.propTypes = {
	listWarehouseStaff: PropTypes.array,
	loading: PropTypes.bool,
};
export default withTranslation()(List);
